<script>
import { productService } from "../../../../helpers/backend/product.service";
import { customerService } from "../../../../helpers/backend/customer.service";
import { transactionService } from "../../../../helpers/backend/transaction.service";
import { workerService } from "../../../../helpers/backend/worker.service";
import PrintPreview from "./print-preview";

import itemTemplate from "./item-template.vue";
import productItemTemplate from "./product-item-template.vue";
export default {
  components: { PrintPreview },
  props: {
    isUpdateMode: Boolean,
  },
  data() {
    return {
      form: {
        transaction_id: null,
        customer: {
          vehicle_number: "",
          customer_name: "",
          phone_number: "",
          vehicle_type: "",
          customer_id: null,
        },
        products: [],
        worker_id: null,
        transaction_date: new Date().toISOString(),
      },
      worker_options: [{ text: "Select One of worker", value: null }],
      product: {
        auto_id: null,
        ID: null,
        product_name: "",
        vehicle_type: "",
        unit: "",
        description: "",
        price: null,
        max_qty: null,
        qty: 1,
        hide_qty: false,
        hide_price: false,
        custom_product_name: "",
      },

      fields: [
        { key: "qty", sortable: false, label: "Qty" },
        { key: "product_name", sortable: false, label: "Product Name" },
        { key: "price", sortable: false, label: "Price" },
        { key: "total", sortable: false, label: "Total" },
        { key: "action" },
      ],
      product_fields: [
        { key: "ID", sortable: true, label: "Product ID" },
        { key: "product_name", sortable: true, label: "Product Name" },
        { key: "description", sortable: true, label: "Description" },
        { key: "vehicle_type", sortable: true, label: "Vehicle Type" },
        { key: "price", sortable: true, label: "Price" },
        { key: "qty", sortable: true, label: "Quantity" },
        { key: "unit", sortable: true, label: "Unit" },
      ],
      showModal: false,
      productModalModel: false,
      busy: false,
      vehicle_type_options: [{ text: "Universal", value: 0 }],
      show: true,
      showDismissibleAlert: false,
      error: "",
      itemTemplate,
      productItemTemplate,
      customerItems: [],
      productItems: [],
      auto_id: 1,
      custom_name: false,
      products: [],
      product_filter: "",
      selectedProduct: null,
    };
  },
  created() {
    this.fetchData();
  },
  computed: {
    isUpdateState() {
      return this.form.id != null;
    },

    isStaff() {
      const loggeduser = localStorage.getItem("user");
      const jsonData = JSON.parse(loggeduser);
      return jsonData.data.role_level == 1;
    },
  },
  methods: {
    onSubmit(event) {
      event.preventDefault();
      this.$bvModal
        .msgBoxConfirm("Are you sure to submit this Transaction?")
        .then((value) => {
          if (!value) return;
          if (!this.form.transaction_date.includes("T"))
            this.form.transaction_date += "T00:00:00Z";
          this.busy = true;
          transactionService
            .createTransaction(JSON.parse(JSON.stringify(this.form)))
            .then(
              (data) => {
                if (data != null) {
                  this.reset();
                  this.form.products = [];
                  this.showDismissibleAlert = true;
                  this.error = "";
                  if (!this.isUpdateMode) {
                    this.showModal = true;
                    window.setTimeout(() => {
                      this.$refs.printPreview.fetchData(data.transaction.ID);
                    }, 500);
                  }
                }
                this.busy = false;
              },
              (error) => {
                this.showDismissibleAlert = true;
                this.error = "Failed to Create Transaction. Error : " + error;
                this.busy = false;
              }
            );
        })
        .catch(() => {
          // An error occurred
        });
      //this.form.media = this.$refs.file.files[0]
    },
    addProduct(event) {
      event.preventDefault();
      if (this.product.ID < 1) return;
      if (this.form.products.length >= 6) {
        this.$bvModal.msgBoxOk(
          "Can't add more product, Please create new Transaction instead."
        );
        return;
      }
      this.product.price = parseInt(this.product.price);
      this.product.qty = parseInt(this.product.qty);
      this.product.auto_id = JSON.parse(
        JSON.stringify({ auto_id: this.auto_id++ })
      ).auto_id;
      this.form.products.push({ ...this.product });
      this.resetProduct();
    },
    reset() {
      // Reset our form values
      this.form.transaction_id = null;
      this.worker_id = null;
      this.auto_id = 1;
      this.transaction_date = new Date().toISOString();
      this.resetCustomer();
      // Trick to reset/clear native browser form validation state
      this.show = false;
      this.showDismissibleAlert = false;
      this.error = "";
      this.$nextTick(() => {
        this.show = true;
      });
      this.$emit("onReset");
    },
    onReset(event) {
      event.preventDefault();
      this.reset();
    },
    fetchData() {
      workerService.getAll().then(
        (data) => {
          this.worker_options = this.worker_options.concat(
            data.workers.map(function (mType) {
              return { text: mType.worker_name, value: mType.ID };
            })
          );
          //alert(typeof(this.machine_types))
        },
        (err) => {
          this.showDismissibleAlert = true;
          this.error = "Failed to get vehicle type. Error : " + err;
        }
      );
    },
    // Autocomplete for customer
    inputCustomerChange(text) {
      if (text.length > 1) {
        customerService.getAll(text).then((data) => {
          this.customerItems = data.customers.map(function (cust) {
            return {
              customer_id: cust.ID,
              customer_name: cust.name,
              vehicle_type:
                cust.vehicle_type.vehicle_brand +
                " " +
                cust.vehicle_type.vehicle_series +
                " " +
                cust.vehicle_year,
              vehicle_number: cust.vehicle_number,
              phone_number: cust.phone_number,
            };
          });
        });
      }
    },
    itemCustomerSelected(item) {
      this.form.customer = item;
    },
    setCustomerLabel(item) {
      return item.vehicle_number;
    },
    resetCustomer() {
      for (var key in this.form.customer) {
        this.form.customer[key] = null;
      }
      if (!this.isUpdateMode) {
        this.resetProduct();
        this.form.products = [];
      }
    },
    // Autocomplete for product

    inputProductChange(text) {
      // your search method
      if (text.length > 1) {
        productService.getAll(text).then((data) => {
          this.productItems = data.products.map(function (prod) {
            return {
              ID: prod.ID,
              product_name: prod.product_name,
              unit: prod.unit,
              description: prod.description,
              vehicle_type_name:
                prod.vehicle_type.vehicle_brand +
                " " +
                prod.vehicle_type.vehicle_series,
              price: prod.price,
              max_qty: prod.qty,
              qty: prod.qty > 0 ? 1 : 0,
            };
          });
        });
      }

      //this.items = this.items.filter((item) => item.name.includes(text));
      // now `items` will be showed in the suggestion list
    },
    itemProductSelected(item) {
      this.product = item;
    },
    setProductLabel(item) {
      return item.product_name;
    },
    resetProduct() {
      for (var key in this.product) {
        this.product[key] = null;
      }
    },
    onDeleteProduct(id) {
      this.form.products = this.form.products.filter((p) => p.auto_id != id);
    },
    confirmPrint(event) {
      event.preventDefault();
      this.showModal = !this.$refs.printPreview.print();
    },
    cancelPrint(event) {
      event.preventDefault();
      this.showModal = !this.$refs.printPreview.cancel();
    },
    // Product

    showProductModal() {
      this.productModalModel = true;
      this.product_filter = "";
      productService.getAll().then((data) => {
        if (data.products) {
          this.products = data.products;
        }
      });
    },
    productSelect(event) {
      this.selectedProduct = { ...event[0] };
      this.selectedProduct.qty = 0;
    },
    dblClickProductSelect(event) {
      console.log(event);
      this.selectedProduct = { ...event };
      this.selectedProduct.qty = 0;
      this.confirmSelectProduct(null);
    },
    initUpdate(id) {
      this.reset();
      transactionService.getById(id).then(
        (data) => {
          if (data.transaction) {
            var transaction = data.transaction;
            this.form.transaction_id = transaction.ID;
            this.form.customer = {
              customer_id: transaction.customer.ID,
              customer_name: transaction.customer.name,
              vehicle_type:
                transaction.customer.vehicle_type.vehicle_brand +
                " " +
                transaction.customer.vehicle_type.vehicle_series +
                " " +
                transaction.customer.vehicle_year,
              vehicle_number: transaction.customer.vehicle_number,
              phone_number: transaction.customer.phone_number,
            };
            this.form.transaction_date = transaction.transaction_date;
            this.form.worker_id = transaction.worker_id;
            for (
              let index = 0;
              index < transaction.detail_transactions.length;
              index++
            ) {
              const element = transaction.detail_transactions[index];
              this.form.products.push({
                auto_id: this.auto_id++,
                ID: element.product.ID,
                product_name: element.product.product_name,
                unit: element.unit,
                price: element.price,
                qty: element.qty,
                hide_qty: element.hide_qty,
                hide_price: element.hide_price,
                custom_product_name: element.custom_product_name,
              });
            }
          }
        },
        (err) => {
          console.log(err);
        }
      );
    },
    confirmSelectProduct(event) {
      if (event) event.preventDefault();
      if (this.selectedProduct == null) {
        return;
      }
      this.product = this.selectedProduct;
      this.selectedProduct = null;

      this.$nextTick(() => {
        this.productModalModel = false;
      });
    },
  },
};
</script>

<style scope>
.vue-suggestion,
.vs__input-group {
  display: inline-block;
  margin-right: 10px;
}
.vue-suggestion .vs__list {
  width: 100%;
  text-align: left;
  border: none;
  border-top: none;
  max-height: 400px;
  overflow-y: auto;
  border-bottom: 1px solid #023d7b;
  position: relative;
  z-index: 100;
}
.vue-suggestion .vs__list .vs__list-item {
  background-color: #fff;
  padding: 10px;
  border-left: 1px solid #023d7b;
  border-right: 1px solid #023d7b;
}
.vue-suggestion .vs__list .vs__list-item:last-child {
  border-bottom: none;
}
.vue-suggestion .vs__list .vs__list-item:hover {
  background-color: #eee !important;
}
.vue-suggestion .vs__list,
.vue-suggestion .vs__loading {
  position: absolute;
  z-index: 100;
}
.vue-suggestion .vs__list .vs__list-item {
  cursor: pointer;
}
.vue-suggestion .vs__list .vs__list-item.vs__item-active {
  background-color: #f3f6fa;
}
#products-select-table .table-responsive {
  height: 60vh;
}
</style>

<template>
  <div v-if="!isUpdateMode || this.form.transaction_id">
    <b-modal
      v-model="showModal"
      @ok="confirmPrint"
      @cancel="cancelPrint"
      ok-title="Print"
      size="xl"
      id="my-modal"
    >
      <PrintPreview ref="printPreview" />
    </b-modal>
    <b-modal
      v-model="productModalModel"
      @ok="confirmSelectProduct"
      size="xl"
      id="product-modal"
    >
      <div class="col-sm-12 col-md-12">
        <div id="tickets-table_filter" class="dataTables_filter text-md-right">
          <label class="d-inline-flex align-items-center">
            Search:
            <b-form-input
              v-model="product_filter"
              type="search"
              class="form-control form-control-sm ml-2"
            ></b-form-input>
          </label>
        </div>
      </div>
      <div id="products-select-table">
        <b-table
          :fields="product_fields"
          :items="products"
          selectable
          responsive
          select-mode="single"
          :filter="product_filter"
          @row-selected="productSelect"
          @row-dblclicked="dblClickProductSelect"
        >
          <template v-slot:cell(vehicle_type)="row">
            {{
              row.item.vehicle_type.vehicle_brand != ""
                ? row.item.vehicle_type.vehicle_brand +
                  " " +
                  row.item.vehicle_type.vehicle_series
                : "Universal"
            }}
          </template>
        </b-table>
      </div>
    </b-modal>
    <b-alert
      :variant="error == '' ? 'success' : 'danger'"
      dismissible
      fade
      :show="showDismissibleAlert"
      @dismissed="showDismissibleAlert = false"
    >
      <div v-if="error != ''">
        {{ error }}
      </div>
      <div v-else>Success perform Insert/Update action</div>
    </b-alert>
    <b-overlay :show="busy" rounded="lg" opacity="0.6">
      <div class="card">
        <div class="card-body overflow-auto" style="max-height: 600px">
          <div>
            <div class="row">
              <div class="col-xl-6">
                <b-form-group
                  id="input-group-transaction-date"
                  label="Transaction Date:"
                  label-for="input-transaction-date"
                >
                  <b-datepicker
                    id="input-transaction-date"
                    v-model="form.transaction_date"
                    required
                  />
                </b-form-group>
              </div>
            </div>
            <div class="row">
              <div class="col-xl-3">
                <b-form-group
                  id="input-group-vehicle-number"
                  label="Vehicle number:"
                  label-for="input-vehicle-number"
                >
                  <div v-if="form.customer.customer_id">
                    <div
                      class="badge font-size-12 badge-success"
                      style="float: left"
                    >
                      {{ form.customer.vehicle_number }}
                    </div>
                    <b-button-close
                      style="color: red"
                      @click="resetCustomer"
                    ></b-button-close>
                  </div>
                  <template v-else>
                    <vue-suggestion
                      :items="customerItems"
                      @changed="inputCustomerChange"
                      :setLabel="setCustomerLabel"
                      @selected="itemCustomerSelected"
                      :itemTemplate="itemTemplate"
                    >
                    </vue-suggestion>
                  </template>
                </b-form-group>
              </div>
              <div class="col-xl-3">
                <b-form-group label="Customer name:">
                  <div class="card-title">
                    {{ form.customer.customer_name }}
                  </div>
                </b-form-group>
              </div>
              <div class="col-xl-3">
                <b-form-group label="Customer Phone Number:">
                  <div class="card-title">
                    {{ form.customer.phone_number }}
                  </div>
                </b-form-group>
              </div>
              <div class="col-xl-3">
                <b-form-group label="Customer Vehicle:">
                  <div class="card-title">
                    {{ form.customer.vehicle_type }}
                  </div>
                </b-form-group>
              </div>
            </div>
            <div class="row">
              <div class="col-xl-6">
                <b-form-group
                  id="input-group-worker"
                  label="Worker:"
                  label-for="input-worker"
                >
                  <b-form-select
                    id="input-worker"
                    v-model="form.worker_id"
                    :options="worker_options"
                    required
                  ></b-form-select>
                  <b-form-invalid-feedback :state="form.worker_id != null">
                    You need to select one of the worker
                  </b-form-invalid-feedback>
                </b-form-group>
              </div>
            </div>
            <hr />
            <div class="row">
              <div class="col-xl-4">
                <b-form
                  @submit="addProduct"
                  @reset="onReset"
                  v-if="show"
                  ref="form"
                >
                  <b-form-group
                    id="input-group-1"
                    label="Product Name:"
                    label-for="input-1"
                  >
                    <div v-if="product.ID">
                      <div
                        class="badge font-size-12 badge-success"
                        style="float: left"
                        v-if="custom_name == false"
                      >
                        {{ product.product_name }}
                      </div>
                      <div v-else>
                        <b-form-input
                          id="input-cust-prod-name"
                          v-model="product.custom_product_name"
                          type="text"
                        ></b-form-input>
                      </div>
                      <b-button-close
                        style="color: red"
                        @click="resetProduct"
                      ></b-button-close
                      ><br />
                    </div>
                    <template v-else>
                      <vue-suggestion
                        :items="productItems"
                        @changed="inputProductChange"
                        :setLabel="setProductLabel"
                        @selected="itemProductSelected"
                        :itemTemplate="productItemTemplate"
                      >
                      </vue-suggestion>
                      <b-button @click="showProductModal"
                        ><i class="ri-search-line"></i
                      ></b-button>
                    </template>

                    <b-form-checkbox
                      id="input-cust-name-hide"
                      v-model="custom_name"
                      v-show="product.ID > 0"
                      >Custom product name</b-form-checkbox
                    >
                  </b-form-group>
                  <b-form-group
                    id="input-group-2"
                    label="Description:"
                    label-for="input2"
                  >
                    <div class="card-title">
                      {{ product.description }}
                    </div>
                  </b-form-group>
                  <b-form-group
                    id="input-group-3"
                    label="Price:"
                    label-for="input3"
                  >
                    <div class="card-title" v-if="isStaff">
                      {{ product.price }}
                    </div>
                    <b-form-input
                      v-else
                      id="input-3"
                      v-model="product.price"
                      :disabled="isStaff"
                      v-show="product.ID > 0"
                      type="number"
                      required
                    ></b-form-input>
                    <b-form-checkbox
                      id="input-3-hide"
                      v-model="product.hide_price"
                      v-show="product.ID > 0"
                      >Hide Price</b-form-checkbox
                    >
                  </b-form-group>
                  <b-form-group
                    id="input-group-4"
                    label="Quantity:"
                    label-for="input4"
                  >
                    <b-form-input
                      id="input-4"
                      v-model="product.qty"
                      type="number"
                      :max="product.max_qty"
                      min="1"
                      v-show="product.ID > 0"
                      required
                    ></b-form-input>
                    <b-form-checkbox
                      id="input-4-hide"
                      v-model="product.hide_qty"
                      v-show="product.ID > 0"
                      >Hide Qty</b-form-checkbox
                    >
                  </b-form-group>
                  <b-form-group
                    id="input-group-5"
                    label="Unit:"
                    label-for="input5"
                  >
                    <b-form-input
                      id="input-5"
                      v-model="product.unit"
                      type="text"
                      placeholder="Enter Product Unit"
                      v-show="product.ID > 0"
                      required
                    ></b-form-input>
                  </b-form-group>

                  <b-form-group
                    id="input-group-4"
                    label="Part Vehicle Type:"
                    label-for="input-4"
                  >
                    <div class="card-title">
                      {{ product.vehicle_type_name }}
                    </div>
                  </b-form-group>

                  <b-button type="submit" variant="primary">{{
                    isUpdateState ? "Update" : "Add"
                  }}</b-button>
                  <!-- <b-button type="reset" variant="danger">Reset</b-button> -->
                </b-form>
              </div>
              <div class="col-xl-8">
                <div
                  class="table-responsive overflow-auto"
                  style="max-height: 500px"
                >
                  <b-table
                    :items="form.products"
                    :fields="fields"
                    responsive="sm"
                  >
                    <template v-slot:cell(product_name)="row">
                      {{
                        row.item.custom_product_name &&
                        row.item.custom_product_name.length > 0
                          ? row.item.custom_product_name
                          : row.item.product_name
                      }}
                    </template>
                    <template v-slot:cell(qty)="row">
                      {{ row.item.qty + " " + row.item.unit }}
                    </template>
                    <template v-slot:cell(total)="row">
                      {{
                        "Rp " +
                        (row.item.qty * row.item.price)
                          .toString()
                          .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1\.")
                      }}
                    </template>
                    <template v-slot:cell(price)="row">
                      {{
                        "Rp " +
                        row.item.price
                          .toString()
                          .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1\.")
                      }}
                    </template>

                    <template v-slot:cell(action)="row">
                      <a
                        @click="onDeleteProduct(row.item.auto_id)"
                        class="text-danger"
                        v-b-tooltip.hover
                        title="Delete"
                      >
                        <i class="mdi mdi-trash-can font-size-18"></i>
                      </a>
                    </template>
                  </b-table>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-xl-12">
                <b-button
                  type="submit"
                  @click="onSubmit"
                  v-show="form.products.length > 0 && form.worker_id != null"
                  class="float-right"
                  variant="primary"
                  >Submit</b-button
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-overlay>
  </div>
</template>



