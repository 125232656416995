<script>
import { transactionService } from "../../../../helpers/backend/transaction.service";
/**
 * Transactions component
 */
export default {
  data() {
    return {
      listData: [],
      transaction: null,
      loading: false,
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      filter: null,
      filterOn: [],
      sortBy: "orderid",
      sortDesc: false,
      buktiTerima: false,
      fields: [
        { key: "qty", label: "Qty", thStyle: { width: "10%" } },
        {
          key: "product.product_name",
          label: "Nama Barang",
          thStyle: { width: "40%" },
        },
        {
          key: "price",
          label: "Harga",
          thStyle: { width: "25%" },
        },
        {
          key: "total",
          label: "Total",
          thStyle: { width: "25%" },
        },
      ],
      showDismissibleAlert: false,
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    grandTotal() {
      return this.listData.filter( val => !val.placeholder).reduce((sum, cur) => sum + cur.price * cur.qty, 0);
    },
    rows() {
      return this.listData.length;
    },
  },
  created() {},
  mounted() {
    // Set the initial number of items
    this.totalRows = this.listData.length;
  },
  methods: {
    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    fetchData(transactionID) {
      this.error = null;
      this.loading = true;
      this.showDismissibleAlert = false;
      //const fetchedId = this.$route.params.id
      transactionService.getById(transactionID).then(
        (data) => {
          this.transaction = data.transaction;
          this.listData = this.transaction.detail_transactions;
          for (let index = this.listData.length; index < 6; index++) {
            this.listData.push({placeholder : true})
            
          }
          for (let index = 0; index < this.listData.length; index++) {
             if (this.listData[index].custom_product_name.length > 0){
             this.listData[index].product.product_name = this.listData[index].custom_product_name;
            }
          }
          this.loading = false;
        },
        (err) => {
          this.error =
            "Failed to fetch product data. Error : " +
            err +
            "  Dissmiss to refresh";
          this.loading = false;
          this.showDismissibleAlert = true;
        }
      );
    },
    padDigits(number, digits) {
      return (
        Array(Math.max(digits - String(number).length + 1, 0)).join(0) + number
      );
    },
    getDateString(createdAt) {
      var fromDate = new Date(createdAt);
      const monthNames = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "Mei",
        "Jun",
        "Jul",
        "Agu",
        "Sep",
        "Oct",
        "Nov",
        "Des",
      ];
      return (
        fromDate.getDate() +
        "-" +
        monthNames[fromDate.getMonth()] +
        "-" +
        fromDate.getFullYear()
      );
    },
    cancel() {
      return true;
      // var tmp = this.buktiTerima;
      // if (!this.buktiTerima) {
      //   this.buktiTerima = !this.buktiTerima;
      // }
      // return tmp;
    },
    print() {
      if (!this.transaction) return false;
      // Get HTML to print from element
      const prtHtml = document.getElementById("print").innerHTML;
      // Get all stylesheets HTML
      let stylesHtml = "";
      for (const node of [
        ...document.querySelectorAll('link[rel="stylesheet"], style'),
      ]) {
        stylesHtml += node.outerHTML;
      }

      // Open the print window
      const WinPrint = window.open(
        "",
        "",
        "left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0"
      );
      console.log(prtHtml)
      var scndHtml = prtHtml.replace("Hormat Kami","Tanda Terima").replace('<div class="card border border-secondary" style="padding: 15px;"><div class="row"><div class="col-sm-4"><div class="row" style="margin-left: 20px;"></div>','<div class="card border border-secondary" style="padding: 15px;"><div class="row"><div class="col-sm-4"><div class="row" style="margin-left: 20px;">*Sebagai Bukti Pembayaran</div>')
      WinPrint.document.write(`<!DOCTYPE html>
      <html>
        <head>
          ${stylesHtml}
          <!-- <style>@media print{@page {size: landscape}}</style> -->
        </head>
        <body>
          ${prtHtml}
          </br>
          ${scndHtml}
        </body>
      </html>`);

      WinPrint.document.close();
      window.setTimeout(() => {
        WinPrint.focus();
        WinPrint.print();
      }, 200);
      window.setTimeout(() => {
        WinPrint.close();
      }, 700);
      return this.cancel();
    },
  },
};
</script>

<style>
.table-bordered th,
.table-bordered td {
  border: 3px solid #000000 !important;
  font-weight: 500;
  color: black;
  font-family: Arial, Helvetica, sans-serif;
}
.rowClass {
  min-height: 0.8rem;
  line-height: 0.8rem;
  height: 0.8rem;
  text-align: center;
  font-size: 1.3rem;
}
.print-preview-class th {
  text-align: center;
  font-weight: 700;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1.3rem;
}
.g-total-left {
  border: 3px solid #000000 !important;
  border-top: none !important;
  max-width: 24.55%;
  font-weight: 500;
  color: black;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1rem;
}
.g-total-left > div {
  text-align: center;
  position: relative;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 1.3rem;
}
.g-total-right > div {
  text-align: center;
  position: relative;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 1.3rem;
}
.g-total-right {
  border: 3px solid #000000 !important;
  border-left: none !important;
  border-top: none !important;
  max-width: 24.3%;
  font-weight: 500;
  color: black;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1rem;
}
.g-total-right > div {
  position: relative;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
table > caption {
  padding-top: 0;
}
</style>

<template>
  <div>
    <b-alert
      variant="danger"
      dismissible
      fade
      :show="showDismissibleAlert"
      @dismissed="fetchData"
    >
      {{ error }}
    </b-alert>
    <!-- <div class="row">
      <button @click="print">Print</button>
    </div> -->
    <div id="print" :v-if="transaction != null">
      <div class="card border border-secondary" style="padding: 14px">
        <div class="row">
          <div class="col-sm-4">
            <div class="row" style="margin-left: 20px">
              <!--ReplaceMe-->
            </div>
            <div class="row">
              <img
                src="@/assets/images/logo-light.png"
                alt
                height="100"
                style="margin: 20px"
              />
            </div>
          </div>
          <div class="col-sm-8">
            <div class="row offset-sm-2" style="margin-bottom: 21px">
              <div class="font-weight-bold text-justify h4">
                Palembang,
                {{
                  !!transaction && !!transaction.CreatedAt
                    ? getDateString(transaction.CreatedAt)
                    : ""
                }}
              </div>
            </div>
            <div class="row">
              <div class="col-sm-5">
                <div class="font-weight-bold text-justify h4">
                  Nomor Kendaraan :
                </div>
              </div>

              <div class="col-sm-5">
                <div class="font-weight-bold text-justify h4">
                  <u> {{ transaction.customer.vehicle_number }}</u>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-4 offset-sm-1">
                <div class="font-weight-bold text-justify">
                  <b>Worker : {{ transaction.worker.worker_name }}</b>
                </div>
              </div>
              <div class="col-sm-5">
                <div
                  class="font-weight-bold text-justify"
                  style="font-family: 'Inter', sans-serif"
                >
                  {{
                    transaction.customer.vehicle_type.vehicle_brand +
                    " " +
                    transaction.customer.vehicle_type.vehicle_series
                  }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card-body">
          <h4 class="card-title mb-4">
            Nomor : {{ padDigits(transaction.ID, 4) }}
          </h4>

          <div class="table-responsive" style="overflow: hidden">
            <b-table
              :items="listData"
              :fields="fields"
              responsive="sm"
              :per-page="perPage"
              :current-page="currentPage"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :filter="filter"
              :filter-included-fields="filterOn"
              class="print-preview-class"
              tbody-tr-class="rowClass"
              @filtered="onFiltered"
              bordered
            >
              <template v-slot:cell(product_name)="row">
                {{
                  row.item.custom_product_name &&
                  row.item.custom_product_name.length > 0
                    ? row.item.custom_product_name
                    : row.item.product
                }}
              </template>
              <template v-slot:cell(total)="row">
                {{
                  row.item.placeholder
                    ? ""
                    : "Rp " +
                      (row.item.qty * row.item.price)
                        .toString()
                        .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1\.")
                }}
              </template>
              <template v-slot:cell(price)="row">
                {{
                  row.item.placeholder || row.item.hide_price
                    ? "&#8203;"
                    : "Rp " +
                      row.item.price
                        .toString()
                        .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1\.")
                }}
              </template>
              <template v-slot:cell(qty)="row">
                {{
                  row.item.placeholder
                    ? ""
                    : (row.item.hide_qty ? "" : row.item.qty) +
                      " " +
                      row.item.unit
                }}
              </template>
              <template #table-caption>
                <div class="row">
                  <div class="col-sm-6 text-danger">
                    * Semua transaksi dalam bentuk nota printing<br />
                    Sertakan nota sebagai bukti garansi
                  </div>
                  <div class="col-sm-3 g-total-left">
                    <div class="font-weight-bold">Total Rp</div>
                  </div>
                  <div class="col-sm-3 g-total-right">
                    <div class="font-weight-bold">
                      {{
                        "Rp " +
                        grandTotal
                          .toString()
                          .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1\.")
                      }}
                    </div>
                  </div>
                </div>
              </template>
            </b-table>
          </div>

          <div
            class="row text-justify offset-sm-1"
            style="padding-bottom: 70px;padding-top;21px"
          >
            <div class="col-sm-5 font-weight-bold h3">
              {{ buktiTerima ? "Tanda Terima" : "Hormat Kami" }}
            </div>
            <div class="col-sm-7">
              <div class="row font-weight-bold h6">
                <div class="col-sm-2 text-right">BCA</div>
                <div class="col-sm-5 text-center">045.099.313.1</div>
                <div class="col-sm-5 text-left">An Ferry Susanto</div>
              </div>
              <div class="row font-weight-bold h6">
                <div class="col-sm-2 text-right">Mandiri</div>
                <div class="col-sm-5 text-center">112.001.076.091.1</div>
                <div class="col-sm-5 text-left">An Loren Iskandar</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>