import { authHeader } from './auth-header';
import axiosInstance from "./client"

export const transactionService = {
    getAll,
    createTransaction,
    getById,
    deleteById,
};

function getById(id) {
    const requestOptions = {
        url : '/api/v1/transaction/' + id,
        method: 'GET',
        headers: authHeader()
    };
    return axiosInstance(requestOptions)
    //return fetch(`/api/v1/user/machine_type/` + machineTypeId, requestOptions).then(handleResponse);
}

function getAll(filter) {
    var postFix = "";
    if (filter != null ){
        postFix = "?customer_id="+filter
    }
    const requestOptions = {
        url : '/api/v1/transaction' + postFix,
        method: 'GET',
        headers: authHeader()
    };
    return axiosInstance( requestOptions)
}


function createTransaction(jsonData) {

    let rMethod = jsonData["transaction_id"] != null  ? "PUT" : "POST"
    const requestOptions = {
        url : '/api/v1/transaction',
        method: rMethod,
        data: jsonData,
        headers: authHeader()
    };

    return axiosInstance(requestOptions);
}


function deleteById(id) {
    const requestOptions = {
        url: `/api/v1/transaction/` + id,
        method: 'DELETE',
        headers: authHeader()
    };
    return axiosInstance(requestOptions);
}

