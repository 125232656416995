<template>
  <div>
    Vehicle Number : <b>{{ item.vehicle_number }}</b><br>
    Name : <b>{{ item.customer_name }}</b><br>
    Vehicle type : <b>{{ item.vehicle_type }}</b>
    
  </div>
</template>

<script>
export default {
  props: {
    item: { required: true },
  }
}
</script>