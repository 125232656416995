<template>
  <div>
    Name : <b>{{ item.product_name }}</b><br>
    Desc : <b>{{ item.description }}</b><br>
    Vehicle type : <b>{{ item.vehicle_type_name }}</b>
    
  </div>
</template>

<script>
export default {
  props: {
    item: { required: true },
  }
}
</script>